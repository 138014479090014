import styles from "./Text.module.css";

import React, { ReactElement, ReactNode } from "react";
import classnames from "classnames";

type Props = {
  children: ReactNode;
  className?: string;
  weight?: 400 | 500 | 600 | 700 | 800;
  size?: 12 | 14 | 16 | 18 | 20 | 22 | 24 | 28 | 32;
  tag?: "h1" | "h2" | "h3" | "p" | "span" | "small";
  singleline?: boolean;
};

export default function Text(props: Props): ReactElement<Props> {
  const {
    children,
    className,
    weight = 400,
    size = 16,
    singleline,
    tag = "p",
  } = props;

  const textClassName = classnames(className, {
    [styles.weightMedium]: weight === 500,
    [styles.weightSemibold]: weight === 600,
    [styles.weightBold]: weight === 700,
    [styles.weightBlack]: weight === 800,
    [styles.size12]: size === 12,
    [styles.size14]: size === 14,
    [styles.size18]: size === 18,
    [styles.size20]: size === 20,
    [styles.size22]: size === 22,
    [styles.size24]: size === 24,
    [styles.size28]: size === 28,
    [styles.size32]: size === 32,
    [styles.singleline]: !!singleline,
  });

  const Component = tag;

  return <Component className={textClassName}>{children}</Component>;
}
