import styles from "./Layout.module.css";

import React, { ReactElement, ReactNode } from "react";

import Header from "./Header";

type Props = {
  children: ReactNode;
};

export default function Layout(props: Props): ReactElement {
  const { children } = props;

  return (
    <>
      <div className={styles.container}>
        <Header />
        <main>{children}</main>
      </div>
    </>
  );
}
