import React, { ReactElement } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Text from "../components/Text";

export default function NotFoundPage(): ReactElement {
  return (
    <Layout>
      <SEO title="404 Not Found" />
      <Text tag="h1" size={24} weight={600}>
        Not Found
      </Text>
      <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
    </Layout>
  );
}
