import styles from "./Header.module.css";

import React, { ReactElement, useContext } from "react";
import { Link } from "gatsby";

import IconLogo from "./IconLogo";
import { ThemeContext } from "../contexts/theme";

export default function Header(): ReactElement {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logoLink}>
        <IconLogo className={styles.logo} />
      </Link>
      {theme != null ? (
        <button className={styles.themeSwitcher} onClick={toggleTheme}>
          {theme === "dark" ? "🌝" : "🌚"}
        </button>
      ) : null}
    </header>
  );
}
